import script from "./IPLocation.vue?vue&type=script&setup=true&lang=js"
export * from "./IPLocation.vue?vue&type=script&setup=true&lang=js"

import "./IPLocation.vue?vue&type=style&index=0&id=36af2283&lang=css"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerOrbit from 'quasar/src/components/spinner/QSpinnerOrbit.js';
import QList from 'quasar/src/components/item/QList.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QItem,QItemSection,QAvatar,QItemLabel,QBtn,QSpinnerOrbit,QList,QSeparator,QBadge,QMenu,QImg,QLinearProgress});
